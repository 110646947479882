<template>
<div class="app-wrapper">

    <div class="app-content pt-5 p-md-3 p-lg-4 mt-5">
        <div class="container-fluid">

            <div class="row gy-4">
                <div class="col-12">
                    <div class="app-card app-card-account shadow-sm d-flex flex-column align-items-start">
                        <div class="app-card-header p-3 border-bottom-0">
                            <div class="row align-items-center gx-3">

                                <!--//col-->
                                <div class="col-auto">
                                    <h1 class="app-card-title" style="font-size:28px">Penjualan Barang</h1>
                                </div>
                                <!--//col-->
                            </div>
                            <!--//row-->
                        </div>
                        <div class="app-card-body px-4 w-100">
                            <div class="item border-bottom py-3">
                                <div class="row justify-content-end mb-2">
                                    <div class="col-12 col-md-4">
                                        <div class="input-group">
                                            <input type="text" v-model="search" class="form-control" placeholder="Cari Nama Barang" aria-label="Cari Nama Barang">
                                            <button @click="getReset" class="btn btn-outline-secondary" type="button" style="border: 1px solid #e7e9ed"> X </button>
                                            <button @click="getResults" class="btn btn-outline-secondary" type="button" style="border: 1px solid #e7e9ed">Cari</button>
                                        </div>
                                        <!-- <div>
                                            <div class="row">
                                                <div class="col-auto">
                                                    <div class="input-group ">
                                                        <input  type="text" id="search-docs" name="searchdocs" class="form-control search-docs" placeholder="Cari Nama Barang">
                                                        <button  class="btn btn-outline-secondary" type="button" id="button-addon2" style="border: 1px solid #e7e9ed"> X </button>
                                                    </div>
                                                </div>
                                                <div class="col-auto mt-sm-5 mt-md-0">
                                                    <button  type="submit" class="btn app-btn-secondary">Cari</button>
                                                </div>
                                            </div>

                                        </div> -->
                                    </div>
                                    <div class="col-12 col-md-4">
                                        <!-- <select v-model="bulan" @change="getResults" class="form-select" aria-label="Default select example">
                                            <option disabled value="kosong">Pilih Bulan</option>
                                            <option value="">Januari - Desember</option>
                                            <option v-for="(listBulans, index) in listBulan[0]" :key="index" :value="listBulans">{{ listBulan[1][index] }}</option>
                                        </select> -->
                                        <input type="date" class="form-control" v-model="tanggal" @change="getResults">
                                    </div>
                                    <div class="col-12 col-md-4">

                                        <!-- <select v-model="tahun" @change="getResults" class="form-select" aria-label="Default select example">
                                            <option disabled value="">Pilih Tahun</option>
                                            <option :value="listTahun[0]">Tahun {{listTahun[0]}}</option>
                                            <option :value="listTahun[1]">Tahun {{listTahun[1]}}</option>
                                        </select> -->
                                    </div>
                                </div>
                                <div class="table-responsive">
                                    <table class="table table-bordered">
                                        <thead>
                                            <tr>
                                                <th class="cell">No</th>
                                                <th class="cell">Tanggal</th>
                                                <th class="cell">Cabang</th>
                                                <th class="cell">Nama Barang</th>
                                                <th class="cell">Qty</th>
                                                <th class="cell">Harga</th>
                                                <th class="cell">Total</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr v-for="(dataInv, index) in dataInv" :key="index">
                                                <td class="cell">{{index + 1}}</td>
                                                <td class="cell">{{ filterdate(dataInv.created_at)  }}</td>
                                                <td class="cell">{{dataInv.nama_cabang}}</td>
                                                <td class="cell">{{dataInv.nama_barang}}</td>

                                                <td class="cell">
                                                    {{ dataInv.total_qty }}

                                                </td>

                                                <td class="cell">{{"Rp " + (dataInv.harga_fix*((100-dataInv.diskon)/100)).toString().replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1\.")  + ',00'}}</td>
                                                <td class="cell">{{"Rp " + (dataInv.harga_fix*((100-dataInv.diskon)/100)*dataInv.total_qty).toString().replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1\.")  + ',00'}}</td>

                                            </tr>
                                            <!-- 
                        <tr>
                            <th scope="row" colspan="2">Total</th>
                            <td>:</td>
                            <td>{{"Rp " + cektotal().toString().replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1\.")  + ',00'}}</td>
                        </tr>
                        <tr>
                            <th scope="row" colspan="2">Bayar</th>
                            <td>:</td>
                            <td> <input type="text" class="form-control my-nominal" id="my-nominal" v-model="nominal" oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');" placeholder="Masukan Nominal" @keyup.enter="ctkfile"></td>
                        </tr>
                        <tr>
                            <th scope="row" colspan="2">Kembalian</th>
                            <td>:</td>
                            <td>{{"Rp " + kembalian().toString().replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1\.")  + ',00'}}</td>
                        </tr> -->
                                        </tbody>
                                    </table>
                                    <nav class="app-pagination mt-3">
                                        <ul class="pagination justify-content-center">
                                            <li :class="[pagination.prev_page_url == null ?  'disabled page-item'  : 'page-item'] "><a class="page-link" @click="onClicks(pagination.prev_page_url)">Previous</a></li>
                                            <div v-for="item =1 in pagination.last_page" :key="item">
                                                <li :class="[pagination.current_page == item ?  'active page-item'  : 'page-item'] "><a class="page-link" @click="onClick(item)">{{item}}</a></li>
                                            </div>
                                            <li class="page-item" :class="[pagination.next_page_url == null ?  'disabled page-item'  : 'page-item'] "><a class="page-link" @click="onClicks(pagination.next_page_url)">Next</a></li>
                                        </ul>
                                    </nav>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import axios from 'axios'
import moment from 'moment';

export default {
    data() {
        return {
            dataInv: [],
            link: this.globalApiUrl,
            loading: false,
            loggedIn: localStorage.getItem('loggedIn'),
            token: localStorage.getItem('token'),
            pagination: {
                "total": 0,
                "per_page": 8,
                "current_page": 1,
                "last_page": 1,
                "next_page_url": "...",
                "prev_page_url": "...",
                "from": 1,
                "to": 8,
            },
            namaEdit: '',
            nohpEdit: '',
            alamatEdit: '',
            emailEdit: '',
            editMessage: [],
            file: undefined,
            preview: '',
            bulan: '',
            search: '',
            cabang: localStorage.getItem('cabang'),
            tahun: moment().format('YYYY'),
            listTahun: [
                moment().subtract(1, 'years').format('YYYY'),
                moment().format('YYYY')
            ],
            listBulan: [
                [
                    '01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12'
                ],
                [
                    'Januari', 'Febuary', 'Maret', 'April', 'Mei', 'Juni', 'Agustus', 'September', 'Oktober', 'November', 'Desember'
                ]
            ],
            total_qty: [],
            edited: false,
            tanggal: moment().format('YYYY-MM-DD'),
            page: 1,
        }
    },
    created() {
        if (!this.loggedIn) {
            if (!this.token) {
                localStorage.removeItem("loggedIn");
                localStorage.removeItem("token");
                return this.$router.push({
                    name: 'login.index'
                })
            } else {
                localStorage.removeItem("loggedIn");
                localStorage.removeItem("token");
            }
        } else {
            this.getResults();
        }

    },
    methods: {
        getReset() {
            this.search = '';
            this.getResults();
        },
        getResults() {
            axios.get(this.link + 'api/transaksi-produk' + '?barang=' + this.search + '&tanggal=' + this.tanggal+ '&cabang=' + this.cabang+ '&page=' + this.page, {
                headers: {
                    'Authorization': this.token
                }
            }).then(response => {
                console.log(response)

                this.dataInv = response.data.data.data;
                this.pagination = {
                    "total": response.data.data.total,
                    "per_page": response.data.data.per_page,
                    "current_page": response.data.data.current_page,
                    "last_page": response.data.data.last_page,
                    "next_page_url": response.data.data.next_page_url,
                    "prev_page_url": response.data.data.prev_page_url,
                    "from": response.data.data.from,
                    "to": response.data.data.to,
                }
                // assign response to state user
            })
        },
         onClick(page) {
            console.log(page)
            this.page = page;
            this.getResults();
        },
        onClicks(a){
            console.log(a)
            axios.get(a + '&barang=' + this.search + '&tanggal=' + this.tanggal, {
                    headers: {
                        'Authorization': this.token
                    }
                })
                .then(response => {
                    this.dataInv = response.data.data.data;
                    this.pagination = {
                        "total": response.data.data.total,
                        "per_page": response.data.data.per_page,
                        "current_page": response.data.data.current_page,
                        "last_page": response.data.data.last_page,
                        "next_page_url": response.data.data.next_page_url,
                        "prev_page_url": response.data.data.prev_page_url,
                        "from": response.data.data.from,
                        "to": response.data.data.to,
                    }
                }).then(
                    // this.loading = false
                );
        },
        filterdate(a) {
            // console.log(a);
            return moment(String(a)).format('DD/MM/YYYY')
        },
       
    }
}
</script>
